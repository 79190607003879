import React from 'react';
import '../App.css';
import './PortfolioPage.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import { Button } from './Button';


import itforyou from './assets/images/portfolio/itforyou.png';
import axeconstructions from './assets/images/portfolio/axeconstructions.png';
import greenspakuranga from './assets/images/portfolio/greenspakuranga.png';
import interior from './assets/images/portfolio/interior.png';
import loga from './assets/images/portfolio/loga.png';
import luxelectrical from './assets/images/portfolio/luxelectrical.png';
import papadums from './assets/images/portfolio/papadums.png';
import printerneeds from './assets/images/portfolio/printerneeds.png';
import projectmanagement from './assets/images/portfolio/projectmanagement.png';
import resistelectrical from './assets/images/portfolio/Resistelectrical.png';
import savego from './assets/images/portfolio/savego.png';
import senthilmukusha from './assets/images/portfolio/senthilmukusha.png';
import stylecanopies from './assets/images/portfolio/stylecanopies.jpeg';
import treasurekitchen from './assets/images/portfolio/treasurekitchen.png';
import mmgcommunications from './assets/images/portfolio/mmgcommunications.png';
import spotoncleaners from './assets/images/portfolio/spot-oncleaners.png';
import smartkidseducare from './assets/images/portfolio/SmartKidsEducare.png';
import lightspeedpng from './assets/images/portfolio/LightspeedPNG.png';
import lightspeedfiji from './assets/images/portfolio/LightspeedFiji.png';

function PortfolioPage() {
  return (
    <div className='PortfolioSection'>
        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <div class="col-md-6">
            <h1>We design & develop responsive websites.</h1>
            <br />
            <p>This means they can be easily viewed and used across all devices. Be it your phone, computer or iPads, the website automatically fits to the size of your screen, guaranteeing a consistently good user experience.</p>
            </div>
            <div class="col-md-6">
                <div className="parallaxPortfolio"></div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInBottom">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-6">
              <h1>Our Recent Projects.</h1>
              <br />
              <p>We are proud of ourselves in being able to work across a number of disciplines from corporate websites to lifestyle & interiors, ecommerce and media & communications.</p>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Lightspeed Papa New Guinea</h2>
            <p>They provides exceptional expertise in the deployment of satellite internet, managed services, and video streaming solutions in Papua New Guinea. With over 1,000+ satisfied managed services clients.</p>
            <a href="https://lightspeedpng.sslsvc.com/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
          </div>

            <div class="col-md-8">
            <img src={lightspeedpng} alt="iWebDesign Auckland" className="portfolioImage"/>

            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={lightspeedfiji} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>Lightspeed Fiji</h2>
            <p>Official Starlink Reseller in Fiji. They provides exceptional expertise in the deployment of satellite internet, managed services, and video streaming solutions in the Pacific region.</p>
              <a href="https://lightspeed.com.fj/" title="iWebDesign Auckland" target="_blank">
                <Button
                  className='btns'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                >
                  Visit Website  <i class="fas fa-arrow-right"></i>
                </Button>
              </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Smart Kids Educare</h2>
            <p>Smart Kids Educare is a Childcare centre in Auckland. At their centre children love each other, learn through observation and grow together as a group. So they aim at promoting a healthy environment and high quality programme for all groups of children irrespective of age, ability, cultures and genders.</p>
            <a href="https://smartkidseducare.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
          </div>

            <div class="col-md-8">
            <img src={smartkidseducare} alt="iWebDesign Auckland" className="portfolioImage"/>

            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={spotoncleaners} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>Spot-On Cleaners</h2>
            <p>Reliable & Trustworthy Cleaning Services in Auckland.</p>
              <a href="https://www.spotoncleaners.co.nz/" title="iWebDesign Auckland" target="_blank">
                <Button
                  className='btns'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                >
                  Visit Website  <i class="fas fa-arrow-right"></i>
                </Button>
              </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>MMG Communications</h2>
            <p>MMG Communications is a New Zealand registered broadcast and communications firm which owns and operates internet service providers (ISP’s) in New Zealand and Papua New Guinea.</p>
            <a href="https://mmgcommunications.sslsvc.com/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
          </div>

            <div class="col-md-8">
            <img src={mmgcommunications} alt="iWebDesign Auckland" className="portfolioImage"/>

            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={luxelectrical} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>Lux Electrical</h2>
            <p>Lux Electrical Ltd is your trusted commercial electrician in Auckland.</p>
              <a href="https://www.luxelectrical.co.nz/" title="iWebDesign Auckland" target="_blank">
                <Button
                  className='btns'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                >
                  Visit Website  <i class="fas fa-arrow-right"></i>
                </Button>
              </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Axe Constructions</h2>
            <p>Axe Constructions Ltd is a highly-specialized constructions company with vast expertise in residential and commercial development.</p>
            <a href="http://axeconstructions.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
          </div>

            <div class="col-md-8">
            <img src={axeconstructions} alt="iWebDesign Auckland" className="portfolioImage"/>

            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={itforyou} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>IT For You</h2>
            <p>Specialises in providing cyber security and compliance, cloud services, managed services, and IT consultancy to help futureproof your business.</p>
            <a href="http://itforyou.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Resist Electrical</h2>
            <p>Resist Electrical has been providing residential and commercial electrical services to the Auckland area for over 5 years.</p>
            <a href="http://resistelectrical.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
            <div class="col-md-8">
            <img src={resistelectrical} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={stylecanopies} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>Style Canopies</h2>
            <p>With over 25 years experience within the motor industry specialising in automotive accessories.</p>
            <a href="https://www.stylecanopies.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Greens Pakuranga</h2>
            <p>Helping live healthy lives. Greens Pakuranga only the most organic & healthy food available in Auckland.</p>
            <a href="https://greenspakuranga.com/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
            <div class="col-md-8">
            <img src={greenspakuranga} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={projectmanagement} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>Project Management</h2>
            <p>Every home tells a story, renovating not only restores the home but restores your story.</p>
            <a href="http://www.prprojectmanagement.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Professional interior Plastering</h2>
            <p>Specialize in all kinds of gib fixing & painting repair work.</p>
            <a href="http://piplastering.com/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
            <div class="col-md-8">
            <img src={interior} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
              <div class="row">
                <br /><br /><br /><br />
                <div class="col-md-8">
                  <img src={papadums} alt="iWebDesign Auckland" className="portfolioImage"/>
                </div>
                <div class="col-md-4">
                <h2>Papadums</h2>
                <p>The real taste of authentic and traditional South Indian Cuisine.</p>
                <a href="http://www.papadums.co.nz/" title="iWebDesign Auckland" target="_blank">
                  <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                  >
                    Visit Website  <i class="fas fa-arrow-right"></i>
                  </Button>
                </a>
                </div>
              </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Printer Needs</h2>
            <p>kiwi owned and operated company that specialises in providing photocopiers and printers.</p>
            <a href="http://printerneeds.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
            <div class="col-md-8">
            <img src={printerneeds} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
          </div>
      </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-8">
              <img src={senthilmukusha} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
            <div class="col-md-4">
            <h2>Senthil & Mukusha</h2>
            <p>Specialise in Immigration Law, Property Law, and Commercial Law.</p>
            <a href="http://www.senthilmukusha.co.nz/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Loga Pullar</h2>
            <p>The 15 years is a combination of employment with Immigration New Zealand and private consulting services.</p>
            <a href="http://loga.lawyer/" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
            <div class="col-md-8">
              <img src={loga} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInLeft">
            <div class="row">
              <br /><br /><br /><br />
              <div class="col-md-8">
                <img src={treasurekitchen} alt="iWebDesign Auckland" className="portfolioImage"/>
              </div>
              <div class="col-md-4">
              <h2>Treasure Kitchen</h2>
              <p>Authentic Malaysian & Thai Restaurant which locates in Henderson, Auckland.</p>
              <a href="http://treasurekitchen.co.nz/" title="iWebDesign Auckland" target="_blank">
                <Button
                  className='btns'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                >
                  Visit Website  <i class="fas fa-arrow-right"></i>
                </Button>
              </a>
              </div>
            </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn">
          <div class="row">
            <br /><br /><br /><br />
            <div class="col-md-4">
            <h2>Save & Go</h2>
            <p>Finding a low price petrol station near your location.</p>
            <a href="http://iwebdesign.co.nz/portfolio/app/save&go.php" title="iWebDesign Auckland" target="_blank">
              <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
              >
                Visit Website  <i class="fas fa-arrow-right"></i>
              </Button>
            </a>
            </div>
            <div class="col-md-8">
            <img src={savego} alt="iWebDesign Auckland" className="portfolioImage"/>
            </div>
          </div>
        </ScrollAnimation>


    </div>
  );
}

export default PortfolioPage
